export default function (x: string) {
  const initialReg = /^(202[1-9])/
  const reg = /(202[1-9])$/ // Fix it in Dec 2029
  return x
    .replaceAll(' ', '_')
    .replaceAll('*', '')
    .replaceAll(',', '')
    .replaceAll(':', '')
    .replaceAll('%', '')
    .replace(initialReg, 'current_year')
    .replace(reg, 'current_year')
    .toLowerCase()
}
