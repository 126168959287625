import { ref } from 'vue'

const useExpandContainer = () => {
  const isExpandedContainer = ref(false)
  const onExpandContainer = (value) => {
    isExpandedContainer.value = value
  }

  return {
    isExpandedContainer,
    onExpandContainer,
  }
}

export default useExpandContainer
